import { render, staticRenderFns } from "./index.vue?vue&type=template&id=568426de&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CBox: require('@chakra-ui/vue').CBox, CImage: require('@chakra-ui/vue').CImage, CInput: require('@chakra-ui/vue').CInput, CButton: require('@chakra-ui/vue').CButton, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CBox: require('@chakra-ui/vue').CBox, CGrid: require('@chakra-ui/vue').CGrid, CIcon: require('@chakra-ui/vue').CIcon, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CAlertIcon: require('@chakra-ui/vue').CAlertIcon, CAlertDescription: require('@chakra-ui/vue').CAlertDescription, CAlert: require('@chakra-ui/vue').CAlert})
