<template>
  <c-box
    w="100%"
    :bg="['#F2F2F2', 'white']"
    h="100%"
    :pb="isEmptyPrograms ? '40px' : '0px'"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Pilih Ahli Gizi', isCurrent: true },
        ]"
      />
    </Portal>

    <c-box :px="['17.5px','85px']">
      <c-box
        :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
        :border-radius="['none', '12px']"
        w="100%"
        :px="['0','85px']"
        pb="30px"
      >
        <c-box
          w="100%"
          :max-width="['inherit', '1200px']"
          mx="auto"
          :pt="['20px', '0']"
          :display="['flex', 'block']"
          :border-bottom="['1px solid #F2F2F2', 'none']"
          :padding-bottom="['15px', '0']"
          :px="['0','20px']"
        >
          <c-box
            justify-content="center"
            :display="['none', 'flex']"
          >
            <c-heading
              :display="['none', 'block']"
              font-size="20px"
              font-weight="700"
              color="black.900"
              mt="30px"
            >
              List Ahli Gizi
            </c-heading>
          </c-box>
          <c-box
            :w="['100%', 'auto']"
            :margin-top="['0', '30px']"
            :margin-bottom="['0', '30px']"
            d="flex"
            justify-content="center"
            align-items="center"
            gap="8px"
          >
            <c-box
              pos="relative"
              w="100%"
            >
              <c-image
                :src="require('@/assets/ic-search.svg')"
                :h="['14.58px','21.86px']"
                :w="['14.58px','21.86px']"
                alt="icon"
                pos="absolute"
                top="50%"
                left="15px"
                transform="translateY(-50%)"
                z-index="2"
              />
              <c-input
                id="searchId"
                v-model="search"
                type="text"
                w="100%"
                placeholder="Search"
                :border-radius="['1000px', '20px']"
                padding-left="50px"
                pos="relative"
                z-index="1"
                font-family="Roboto"
                :font-size="['14px', '18px']"
                :font-weight="['400', '500']"
                :h="['38px', '62px']"
                @keyup="onSearch"
              />
            </c-box>
            <ModalFilterNutritionist
              :options="listFilterSpecialist"
              :data="filterSpecialist"
              @change="handleChangeFilterSpecialist"
            />
          </c-box>
        </c-box>
        <CBox
          v-if="filterSpecialist.length > 0"
          v-dragscroll
          py="3px"
          :px="['16px', '0px']"
          overflow="hidden"
          :h="['unset', 'calc(3px + 3px + 40px)']"
          position="relative"
          mb="10px"
        >
          <CList
            ref="list"
            d="flex"
            gap="4px"
            :position="['unset','absolute']"
          >
            <CListItem
              v-for="(filter, idx) in filterSpecialist"
              :key="filter"
              style="list-style: none;"
            >
              <c-button
                variant-color="primary"
                variant="outline"
                bg="#C7F9E3"
                rounded="1000px"
                :h="['34px', '40px']"
                @click="handleDeleteFilterSpecialistByIndex(idx)"
              >
                {{ filter }}

                <c-box
                  margin-left="6px"
                  :width="['16px', '18px']"
                  :height="['16px','18px']"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-circle-close.svg')"
                    height="100%"
                    width="100%"
                    fill="#008C81"
                  />
                </c-box>
              </c-button>
            </CListItem>
          </CList>
        </CBox>
        
        <c-grid
          v-if="isLoadingInit"
          w="100%"
          :template-columns="['repeat(1, 1fr)', 'repeat(auto-fit, minmax(400px, 1fr))']"
          gap="10px"
          mb="1rem"
        >
          <SkeletonCardNutritionist 
            v-for="index in 4"
            :key="index"
          />
        </c-grid>

        <c-box
          v-if="!isLoadingInit && hasNutritionistRecommendation"
          :border-bottom="['1px solid #F2F2F2', 'none']"
        >
          <c-box
            w="100%"
            d="flex"
            :justify-content="['start', 'center']"
          >
            <c-heading
              font-family="Roboto"
              :font-size="['14px', '18px']"
              font-weight="500"
              color="superDarkGray.900"
              :mb="['10px', '16px']"
            >
              Ahli gizi yang pernah dipilih
            </c-heading>
          </c-box>
          <c-box>
            <CardNutritionist
              v-if="listNutritionistsRecommendation.length > 0"
              :nutritionists="listNutritionistsRecommendation"
              :available-program="availableProgram"
              :is-disabled-choose-nutritionist="isDisabledChooseNutritionist"
              :universities="universities"
              @on-choose-nutritionist="onChooseNutritionist"
              @on-profile-nutritionist="onOpenDetailNutritionist"
            />
          </c-box>
        </c-box>
        <c-box
          v-if="!isLoadingInit"
          margin-bottom="40px"
        >
          <c-box
            v-if="hasNutritionist"
            w="100%"
            d="flex"
            :justify-content="['start', 'center']"
          >
            <c-heading
              font-family="Roboto"
              :font-size="['14px', '18px']"
              font-weight="500"
              color="superDarkGray.900"
              :my="['10px', '16px']"
            >
              Ahli gizi tersedia
            </c-heading>
          </c-box>
          <c-box v-if="!isEmpty">
            <CardNutritionist
              v-if="hasNutritionist"
              :nutritionists="listNutritionists"
              :available-program="availableProgram"
              :is-disabled-choose-nutritionist="isDisabledChooseNutritionist"
              :universities="universities"
              @on-choose-nutritionist="onChooseNutritionist"
              @on-profile-nutritionist="onOpenDetailNutritionist"
            />
            <c-flex
              justify-content="center"
              w="100%"
              margin-top="40px"
            >
              <c-flex justify-content="center">
                <c-button
                  px="0"
                  py="4px"
                  w="32px"
                  h="32px"
                  background-color="transparent"
                  :is-disabled="currentPage <= 1"
                  @click.prevent="prevPage"
                >
                  <c-icon
                    name="chevron-left"
                    size="24px"
                  />
                </c-button>

                <c-button
                  v-for="page_ in pages"
                  :key="page_"
                  v-chakra="{
                    ':hover': {
                      bg: 'rgba(0, 140, 129, 0.3)',
                      color: 'primary.400',
                    },
                  }"
                  px="0"
                  py="4px"
                  w="32px"
                  h="32px"
                  mx="4px"
                  background-color="rgba(0, 140, 129, 0.3)"
                  color="primary.400"
                  :bg="
                    page_ === currentPage
                      ? ['rgba(0, 140, 129, 0.3)']
                      : ['transparent']
                  "
                  as="router-link"
                  :to="{ name: $route.name, query: { ...$route.query, page: page_ } }"
                >
                  {{ page_ }}
                </c-button>

                <c-button
                  px="0"
                  py="4px"
                  w="32px"
                  h="32px"
                  background-color="transparent"
                  :is-disabled="pages.length === currentPage"
                  @click.prevent="nextPage"
                >
                  <c-icon
                    name="chevron-right"
                    size="24px"
                  />
                </c-button>
              </c-flex>
            </c-flex>
          </c-box>
        </c-box>
        <c-flex
          v-if="isEmpty && !isLoadingInit"
          py="80px"
          px="20px"
          justify-content="center"
        >
          <c-box>
            <c-image
              :src="require('@/assets/empty-nutritionists.svg')"
              alt="empty"
            />
            <c-text
              color="gray.900"
              font-family="Roboto"
              font-size="24px"
              margin-top="20px"
            >
              Ahli gizi tidak ditemukan
            </c-text>
          </c-box>
        </c-flex>
      </c-box>
    </c-box>
    <c-box
      v-if="isProgramFillQuestionnaire || isEmptyPrograms"
      pos="fixed"
      :bottom="['0px', '20px']"
      :right="['0px', '20px']"
      max-width="860px"
      z-index="11"
    >
      <c-alert status="warning">
        <c-alert-icon />
        <c-alert-description>
          <div>
            {{ isProgramFillQuestionnaire ? 'Kamu punya formulir gizi yang belum selesai, mohon untuk melengkapinya' : 'Kamu belum punya program, silakan beli program terlebih dahulu.' }}
          </div>
          <c-button 
            v-if="isProgramFillQuestionnaire"
            size="sm"
            variant="ghost"
            variant-color="warning"
            float="right"
            as="router-link"
            :to="{
              name: 'client.kuisioner',
              params: { programId: programFillQuestionnaire?.id },
            }"
          >
            Lengkapi
          </c-button>
          <c-button
            v-else
            size="sm"
            variant="ghost"
            variant-color="warning"
            float="right"
            as="router-link"
            :to="{ name: 'client.program' }"
          >
            Beli Program
          </c-button>
        </c-alert-description>
      </c-alert>
    </c-box>

    <ModalConfirm
      title="Apa kamu yakin ingin memilih Ahli Gizi ini?"
      :is-open="isConfirm"
      :is-loading="isLoadingNutritionists"
      @close="onCloseConfirm"
      @submit="() => {
        onSubmitNutritionist(selectedNutritionists)
      }"
    />
    <ModalError
      :is-open="isError"
      :image="require('@/assets/images/illustration-130323-raw.jpeg')"
      title="Tidak dapat memilih ahli gizi karena belum memiliki program"
      note=""
      button-text="Beli Program"
      :button-icon="false"
      :button-action="() => {
        $router.push({ name: 'client.select-program' })
      }"
      @close="onCloseError"
    />

    <ModalNutritionists
      :is-show-skeleton="isShowSkeletonModalNutritionist"
      :is-open="isOpenModal"
      :str-number="nutritionistModal.str"
      :specializations="nutritionistModal.specialization"
      :service-hour="nutritionistModal.serviceHour"
      :rating="nutritionistModal.rating"
      :problem-handles="nutritionistModal.problemHandled"
      :photo-url="nutritionistModal.photoUrl"
      :languages="nutritionistModal.languages ? [
        nutritionistModal.languages
      ] : []"
      :client-age-handles="nutritionistModal.clientAgeHandled ? [
        nutritionistModal.clientAgeHandled,
      ] : []"
      :fullname="getFullnameAndTitle(`${nutritionistModal.firstName || ''} ${nutritionistModal.lastName || ''}`.trim(), nutritionistModal.education)"
      :educations="educationsParser(nutritionistModal.education)"
      :work-experiences="workExperiencesParser(nutritionistModal.workExperiences)"
      :chat-active-hours="chatActiveHoursParser(nutritionistModal.activeHour)"
      :service-times="serviceTimesParser(nutritionistModal.serviceTime)"
      with-button-choose-nutritionist
      :is-disabled-button-choose-nutritionist="isDisabledChooseNutritionist || !isHasRemainingQuota(nutritionistModal?.remainingQuota || 0)"
      @on-close="onCloseDetailNutritionist"
      @on-choose-nutritionist="() => onChooseNutritionist(nutritionistModal.id)"
    />
  </c-box>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import ModalConfirm from '@/components/widgets/modal-confirm'
import ModalError from '@/components/widgets/modal-error'
import CardNutritionist from '@/views/client/nutritionists/_widgets/card-nutritionist.vue'
import ModalFilterNutritionist from '@/views/client/nutritionists/_widgets/modal-filter-nutritionist.vue'
import ModalNutritionists from '@/components/widgets/modal-nutritionists'
import modalNutritionistsMixin from '@/components/widgets/modal-nutritionists-mixin'
import SkeletonCardNutritionist from '@/components/skeletons/skeleton-card-nutritionist.vue'
import { reqProgramsChooseNutritionist } from '@/requests/dietela-api/client/req-programs-choose-nutritionist'

export default {
  name: 'ListNutritionistsPage',
  components: {
    ModalFilterNutritionist,
    CardNutritionist,
    BreadcrumbPath,
    ModalNutritionists,
    ModalConfirm,
    ModalError,
    SkeletonCardNutritionist,
  },
  directives: {
    dragscroll,
  },
  mixins: [
    generalMixin,
    mixinsCheckBreakpoints(),
    modalNutritionistsMixin,
  ],
  data() {
    return {
      totalData: 1,
      page: 1,
      perPage: 10,
      search: this.$route.query?.query ?? '',
      timer: null,
      specialization: '',
      listFilterSpecialist: [],
      isMobileFilter: false,
      resListNutritionist: {},
      isOpenModal: false,
      selectedNutritionists: '',
      isConfirm: false,
      isError: false,
      isLoadingNutritionists: false,
      isLoadingInit: true,
      filterSpecialist: this.$route.query?.specialization ? JSON.parse(this.$route.query?.specialization) : [],
      nutritionistModal: {},
      isShowSkeletonModalNutritionist: true,
      // programChooseNutritionist: {},
    }
  },
  computed: {
    ...mapGetters({
      axios: 'axios',
      isAuthenticated: 'auth/isAuthenticated',
      programs: 'profileProgram/history',
      universities: 'general/universities',
      isCompletedQuisionary: 'clients/isCompletedQuisionary',
      programChooseNutritionist: 'clients/programChooseNutritionist',
      programFillQuestionnaire: 'clients/programFillQuestionnaire',
      isHavingPendingQuisionary: 'clients/isHavingPendingQuisionary',
    }),
    listNutritionistsRecommendation() {
      return this.resListNutritionist?.isAlreadyChooseBefore || []
    },
    listNutritionists() {
      return this.resListNutritionist?.listAll || []
    },
    hasNutritionist() {
      return this.listNutritionists.length > 0
    },
    hasNutritionistRecommendation() {
      return this.listNutritionistsRecommendation.length > 0
    },
    canChooseType() {
      return this.resListNutritionist?.canChoose
    },
    isDisabledChooseNutritionist() {
      return this.canChooseType === 'disabled'
    },
    isEmpty() {
      return !this.hasNutritionist && !this.hasNutritionistRecommendation
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.totalData ?? 1
      let perPage = this.perPage ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
    availableProgram() {
      if (Object.keys(this.programChooseNutritionist).length > 0) {
        return this.programChooseNutritionist
      }
      return false
    },
    isEmptyPrograms() {
      if (Object.keys(this.programChooseNutritionist).length == 0) {
        return true
      }
      return false
    },
    isProgramFillQuestionnaire() {
      if (this.isHavingPendingQuisionary) {
        return this.programFillQuestionnaire?.nutritionistId ? true : false
      }
      return false
    },
  },
  watch: {
    selectedNutritionists: {
      immediate: true,
      handler(val) {
        if (val) {
          this.isShowSkeletonModalNutritionist = true
          this.getNutritionist(val).then((res) => {
            this.nutritionistModal = res.data
            this.isShowSkeletonModalNutritionist = false
          })
        }
      },
    },
    filterSpecialist() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        const specialization = this.filterSpecialist.length > 0 ? JSON.stringify(this.filterSpecialist) : undefined

        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
            specialization,
          },
        }) }, 1200)
    },
    $route: {
      immediate: true,  
      async handler(route) {
        this.isLoadingInit = true
        this.fetchListNutritionist(route.query)
          .finally(() => this.isLoadingInit = false)
        
        // check the program that needs to choose a nutritionist
        // this.programChooseNutritionist = await reqProgramsChooseNutritionist(
        //   this.$store.getters.axios,
        // ).then((res) => {
        //   return res.data?.data
        // })
        // this.listRiwayatPrograms()
        // .then(() => {
        // this.listRecomendationNutritionist()
        // })
      },
    },
  },
  mounted() {
    this.loadUniversities()
    if (!this.isAuthenticated) return this.$router.push({ name: 'auth', query: { tab: 'login' } })
  },
  methods: {
    ...mapMutations({
      setFetchPrograms: 'clients/setFetchPrograms',
    }),
    ...mapActions({
      listRiwayatPrograms: 'profileProgram/listHistoryPrograms',
      setSuccessMessages: 'clients/updateSuccessPayload',
      chooseNutritionist: 'clients/chooseNutritionist',
      loadUniversities: 'general/loadUniversities',
      getNutritionist: 'clients/getNutritionistById',
    }),

    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
            query: this.search || undefined,
          },
        })
      }, 800)
    },
    onChangeSpecialization(e) {
      this.specialization = e
      this.isMobileFilter = false
      if (e === 'Semua spesialisasi') {
        this.specialization = ''
      }
      this.$router.replace({
        name: this.$route.name,
        query: {
          page: 1,
          query: this.search,
          specialization: this.specialization,
        },
      })
    },
    async fetchListNutritionist({ page = 1, query = null, specialization = null } = {}) {
      const params = {
        perpage: this.perPage,
        page,
        ...(specialization && { specialization }),
        ...(query && { q: query }),
      }
      // let params = new URLSearchParams()
      // params.set('perpage', this.perPage)
      // params.set('page', page)
      // params.set('specialization', this.specialization)
      // if (query != null) params.set('q', query)
      try {
        const res = await this.axios.get('/v1/clients/nutritionist', { params })
        // canChoose:
        // can = user sudah beli program tapi belum memilih ahli
        // cannot = user belum memiliki program
        // disabled = user sudah memiliki program dan memilih ahli gizi
        this.resListNutritionist = res.data?.data
        this.totalData = res.data?.meta?.total
        this.listFilterSpecialist = res.data?.meta?.filter.filter((it) => it)
      } catch (e) {
        console.error(e)
      }
    },
    onOpenConfirm() {
      this.isConfirm = true
    },
    onCloseConfirm() {
      this.isConfirm = false
      if (!this.isOpenModal) {
        this.selectedNutritionists = ''
      }
    },
    onOpenError() {
      this.isError = true
    },
    onCloseError() {
      this.isError = false
    },
    onOpenDetailNutritionist(id) {
      this.isOpenModal = true
      this.selectedNutritionists = id
    },
    onCloseDetailNutritionist() {
      this.isOpenModal = false
    },
    onChooseNutritionist(id) {
      this.onCloseDetailNutritionist()
      if (this.canChooseType === 'cannot') {
        this.isError = true
        return
      }

      this.selectedNutritionists = id
      this.isConfirm = true
    },
    async onSubmitNutritionist() {
      this.isLoadingNutritionists = true

      let programId = this.availableProgram?.id
      while (!programId) {
        const programChooseNutritionist = await reqProgramsChooseNutritionist(
          this.$store.getters.axios,
        ).then((res) => res.data?.data)

        if (programChooseNutritionist?.id) {
          programId = programChooseNutritionist?.id
        }
      }

      this.chooseNutritionist({
        programId: programId,
        nutritionist: {
          nutritionistId: this.selectedNutritionists,
        },
      })
        .then(() => {
          let idx = this.listNutritionistsRecommendation.findIndex(
            (dt) => dt.id == this.selectedNutritionists,
          )
          if (idx > -1) {
            this.listNutritionistsRecommendation[idx].whoHandle = true
          }

          this.setSuccessMessages({
            title: 'Pilih Ahli Gizi',
            body: 'Berhasil Pilih Ahli Gizi',
            description: 'Kamu berhasil memilih ahli gizi',
          })
          this.$router
            .push({
              name: this.isCompletedQuisionary ? 'client.nutritionists.done-extend' : 'client.nutritionists.done',
              query: { program_id: this.availableProgram?.id },
            })
            .catch()
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message || 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.isLoadingNutritionists = false
          this.isOpenModal = false
          this.isConfirm = false
          this.selectedNutritionists = ''
        })
    },
    prevPage() {
      this.page -= 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
    nextPage() {
      this.page += 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
    handleDeleteFilterSpecialistByIndex(index) {
      this.filterSpecialist.splice(index, 1)
    },
    handleChangeFilterSpecialist(data) {
      if (typeof data === 'object') {
        this.filterSpecialist = data
        return
      }

      const isAlreadySelected = this.filterSpecialist.findIndex((v) => v === data)
      if (isAlreadySelected > -1) {
        this.handleDeleteFilterSpecialistByIndex(isAlreadySelected)
        return
      }
      this.filterSpecialist.unshift(data)
    },
  },
}
</script>